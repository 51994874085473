import Glide from '@glidejs/glide';

$(document).ready(function() 
{
    const frontHeaderSliderElement = $('.js-front-header-slider');

    if ( frontHeaderSliderElement.length) 
    {
        const frontHeaderSlider = new Glide('.js-front-header-slider', {
            type: 'carousel',
            perView: 1,
            gap: 30,
            animationDuration: 900,
            autoplay: 7000,
        });

        const glideSlides = $('.js-front-header-slider__slide');
        const slideLinkButton = $('.js-front-header-slider__button');

        const setCurrentSlideLink = () => {
            const currentSlideIndex = frontHeaderSlider.index;
            const currentSlideElement = glideSlides.eq(currentSlideIndex);
            const currentSlideLink = currentSlideElement.data('link');
            slideLinkButton.attr('href', currentSlideLink);
        };

        frontHeaderSlider.on(['mount.after', 'run'], setCurrentSlideLink);

        frontHeaderSlider.mount();
    }
});





