import { Alert } from "bootstrap";

class PortfolioDownload {

    constructor()
    {
        // Add on click event
        $('#portfolio-download-button').click( this.PortfolioDownload );
    }

    PortfolioDownload(e) 
    {
        e.preventDefault();

        // Get the field value
        var password = $('#portfolio-download-password').val();
        var postID = $('#portfolio-download-id').val();

        // Payload
        let data = {
            action: 'portfolio_download', // Action name referenced in functions.php
            password: password,
            postID: postID,
        };

        // AJAX Request
        $.ajax({
            type : 'POST',
            url : watermill_params.ajaxurl,
            data : data,
            success: function(response) {
                // Handle the success response
                if (response.success && response.data.redirect_url) 
                {
                    // Redirect to the specified URL
                    window.open(response.data.redirect_url, '_blank');
                    $('.js-download-popup').removeClass('active');
                } 
                else 
                {
                    // Handle specific errors returned by your server
                    // alert('Error: ' + response.data);
                    $('#portfolio-download-password').addClass('error');
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                // Handle AJAX errors
                // alert('AJAX Error: ' + textStatus);
                $('#portfolio-download-password').addClass('error');
            },
        });
    }
}

export { PortfolioDownload };