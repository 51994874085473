import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout'
import ImagesLoaded from 'imagesloaded';

class LoadMore {

    constructor()
    {
        $('.js-load-gallery').on( 'click', this.loadMore );
    }

    loadMore() 
    {
        console.log
        console.log(portfolioID);

        let button = $(this);
        let data = {
                'action': 'loadgallery', // Action name referenced in functions.php
                'portfolioID': portfolioID,
            };

        $.ajax({
            url : watermill_params.ajaxurl,
            data : data,
            type : 'POST',
            beforeSend : function ( xhr ) 
            {
                button.text( 'Loading...' );
            },
            success : function( data )
            {
                $('.js-gallery-container').append( data );
                button.remove();

                jQueryBridget( 'masonry', Masonry, $ );
                jQueryBridget( 'imagesLoaded', ImagesLoaded, $ );

                const $grid = $('.js-gallery-ajax');

                $grid.imagesLoaded(function () {
                    $grid.masonry({
                        itemSelector: '.js-gallery-ajax__item',
                        columnWidth: '.js-gallery-ajax__sizer',
                        percentPosition: true,
                    });
                });
            }
        });
    }
}

export { LoadMore };