// Import Styles
import '../sass/styles.scss';

// Import Plugins
import 'bootstrap';

import './slider/front-header';

import { PortfolioDownload } from './ajax/portfolio-download';
import { LoadMore } from './ajax/load-gallery';

(function() {

    $(document).ready(function() {

        // Setup AJAX
        const portfolioDownload = new PortfolioDownload();
        const loadMore = new LoadMore();

        // Hamburger Menu
        $('.hamburger').on('click', function(e) 
        {
            e.preventDefault();

            $(this).toggleClass('is-active');
            $('.js-header__navigation').toggleClass('is-active');

            if($(this).hasClass('is-active'))
            {
                animateItems();
            }
            else
            {
                // Navigation Animation
                itemsNavigation.removeClass('is-active');
                currentIndex = 0;
            }
        });

        // Click
        $('.js-portfolio-download').on('click', function(e) {
            e.preventDefault();
            $('.js-download-popup').addClass('active');
        });
        $('.js-download-popup-close > span').on('click', function(e) {
            e.preventDefault();
            $('.js-download-popup').removeClass('active');
        });

        // Navigation Animation
        const itemsNavigation = $('.c-navigation-main .menu-item');
        let currentIndex = 0;

        function animateItems() {
            if (currentIndex < itemsNavigation.length) {
            itemsNavigation.eq(currentIndex).addClass('is-active');
            currentIndex++;
            setTimeout(animateItems, 150);
            }
        }

        var portfolioParallax = $('.js-portfolio-parallax');
        var portfolioGrid = $('.js-portfolio-grid');
        var scrollSpeed = 0.06;

        function parallaxScroll() 
        {
            if( !window.matchMedia( '(max-width: 991px)' ).matches )
            {
                var scrolled = $(window).scrollTop();
                portfolioParallax.css('transform', 'translateY(' + -(scrolled * -scrollSpeed) + 'px)');
                portfolioGrid.css('margin-bottom', -(scrolled * -scrollSpeed) + 'px');
            }
        }

        $(window).scroll(function() {

            if( portfolioParallax.length )
                parallaxScroll();
        });

        if( portfolioParallax.length )
                parallaxScroll();
    });

})();